@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
    font-family: 'Intro';
    src: url('../fonts/intro-webfont.woff2') format('woff2'),
    url('../fonts/intro-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";


body {
    font-family: 'Lato', sans-serif;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#30c2f2+0,3b7cc0+100 */
    background: #30c2f2; /* Old browsers */
    background: -moz-linear-gradient(left, #30c2f2 0%, #3b7cc0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #30c2f2 0%, #3b7cc0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #30c2f2 0%, #3b7cc0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#30c2f2', endColorstr='#3b7cc0', GradientType=1); /* IE6-9 */
    margin-bottom: 30px;
    color: #FFFFFF;

    #content {

        background-image: url("../img/bg-header.jpg");
        background-position: top center;
        background-repeat: repeat-x;
        min-height: 450px;

        .table > thead > tr > th,
        .table > tbody > tr > td {
            vertical-align: bottom;
            border-bottom: 2px solid #dddddd70;
        }

        .section-padding {
            padding: 58px 0;

            h1.title {
                font-family: "Intro", sans-serif;
                font-size: 49px;
                line-height: 39px;
                letter-spacing: -0.18px;
                padding: 0;
                margin: 0 0 8px;
                opacity: 0.9;
            }

            h2.sub-title {
                font-size: 27px;
                font-weight: bold;
                padding: 0;
                margin: 0 0 12px;
                opacity: 0.9;
            }

            p.lead {
                font-size: 22px;
                line-height: 28px;
                letter-spacing: -0.05px;
                opacity: 0.9;
            }

            fieldset {
                max-width: 434px;
                min-height: 190px;
                margin: 0 auto 10px;

                legend {
                    font-size: 18px;
                    color: #FFFFFF;
                    border: 0;
                    padding: 10px 0 0 10px;
                    margin: 0 0 15px;
                    opacity: 0.9;
                }

                form {
                    .form-group {
                        margin-bottom: 8px;

                        input {
                            border: 0px;
                        }
                    }
                }
            }

            .img-cellphone {
                margin: 30px auto;
            }

            .download-now-title,
            .training-now-title {
                font-size: 18px;
                font-weight: bold;
                margin-top: 25px;
                margin-bottom: 8px;
                opacity: 0.9;
            }

            .download-now {
                background-image: url("../img/disponivel-google-play-badge.png");
                background-size: contain;
                background-repeat: no-repeat;
                max-width: 188px;
                height: 56px;
                display: block;
                margin: 0 auto;
            }

            .training-now {
                background-image: url("../img/treinamento.png");
                background-size: contain;
                background-repeat: no-repeat;
                max-width: 190px;
                height: 56px;
                display: block;
                margin: 0 auto;
            }

            .contact,
            .contact h4,
            .contact a {
                font-size: 18px;
                font-weight: bold;
                text-decoration: none;
                color: #11176f;
                margin-top: 44px;
            }
        }

        .data {
            .page-header {
                border: 0;

                h2.sub-title {
                    font-family: "Intro", sans-serif;
                    font-size: 29px;
                    line-height: 29px;
                    letter-spacing: -0.18px;
                }
            }
        }
    }
}

@media (max-width: 426px) {
    .section-padding {
        padding: 0 !important;

        .img-cellphone-container {
            min-height: 460px;

            .img-cellphone {
                max-width: 290px;
                margin: 60px auto !important;
            }
        }
    }

    body #content .section-padding .download-now-title, body #content .section-padding .training-now-title {
        font-size: 14px !important;
    }
    .contact {
        > span {
            display: block;
        }
    }
}
